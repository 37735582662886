import * as React from 'react';
import { Translate } from 'react-redux-i18n';

interface Props {
  filters: {[key: string]: boolean};
  toggleTableSpecificFilter: (filterType: string, toggled: boolean) => void;
  dockId: string;
}

interface TableFilterState {

}

export default class UITableSpecificFilter extends React.Component<Props, TableFilterState> {
  constructor(props: Props) {
    super(props);
    this.tableSpecificFilters = this.tableSpecificFilters.bind(this);
  }

  tableSpecificFilters() {
    const { filters } = this.props;
    return Object.keys(filters).map(k => 
      (
          <div className="col-6">
            <input
              type="checkbox"
              name={k}
              id={k}
              key={k + '-input'}
              className="form-check-input"
              checked={filters[k]}
              onChange={(event) => this.props.toggleTableSpecificFilter(k, event.target.checked)}
            />
            <label className="form-check-label" htmlFor={k}>
              <Translate value={`table.${k}`} />
            </label>
          </div>
      )
    );
  }

  render() {
    const {
      dockId
    } = this.props;
    return (
      <div className="row pt-3">

        {this.tableSpecificFilters()}
      </div>
    );
  }
}
