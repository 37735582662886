import { IDock, DockType } from '../../shared/dock/models/dock';
import { DashboardMeta } from '../models/dashboard';
import { Grid } from '../../shared/utils/components/grid';
import { LogAction } from '../../main/middlewares/logger';
import { LogLevel } from '../../shared/logger/models/logger';
import { I18n } from 'react-redux-i18n';

export enum ActionTypes {
  LOAD = '[Dashboard] Load',
  LOAD_SUCCESS = '[Dashboard] Load success',
  LOAD_FAILURE = '[Dashboard] Load failure',
  SAVE = '[Dashboard] Save',
  CONNECT_DOCKS = '[Dashboad] Connect docks',
  RESIZE = '[Dashboard] Resize',
  CLEAN = '[Dashboad] Clean'
}

export interface DashboardLoadAction {
  type: ActionTypes.LOAD;
  payload: DashboardMeta;
}
export interface DashboardLoadSuccessAction extends LogAction {
  type: ActionTypes.LOAD_SUCCESS;
  payload: IDock[];
}

export interface DashboardLoadFailureAction extends LogAction {
  type: ActionTypes.LOAD_FAILURE;
  error: any;
}

export interface DashboardSaveAction extends LogAction {
  type: ActionTypes.SAVE;
  payload: IDock[];
  profileId: string;
}

export interface DashboardConnectDocksAction {
  type: ActionTypes.CONNECT_DOCKS;
  payload: { fromId: string; toId: string, type: DockType };
}

export interface DashboardResizeAction {
  type: ActionTypes.RESIZE;
  payload: { meta: DashboardMeta, prevGrid?: Grid };
}

export interface CleanDashboardAction {
  type: ActionTypes.CLEAN;
}

export function load(meta: DashboardMeta): DashboardLoadAction {
  return {
    type: ActionTypes.LOAD,
    payload: meta
  };
}

export function loadSuccess(docks: IDock[]): DashboardLoadSuccessAction {
  return {
    type: ActionTypes.LOAD_SUCCESS,
    payload: docks,
    logLevel: LogLevel.INFO,
    logMessage: I18n.t('dashboard.log.Dashboard successfully loaded')
  };
}

export function loadFailure(error: any): DashboardLoadFailureAction {
  return {
    type: ActionTypes.LOAD_FAILURE,
    error: error,
    logLevel: LogLevel.ERROR,
    logMessage: I18n.t('dashboard.log.loadFailure')
  };
}

export function save(docks: IDock[], profileId: string): DashboardSaveAction {
  return {
    type: ActionTypes.SAVE,
    payload: docks,
    profileId: profileId,
    logLevel: LogLevel.INFO,
    logMessage: I18n.t('dashboard.log.Saving dashboard.')
  };
}

export function connectDocks(
  fromId: string,
  toId: string,
  type: DockType
): DashboardConnectDocksAction {
  return {
    type: ActionTypes.CONNECT_DOCKS,
    payload: { fromId, toId, type }
  };
}

export function cleanDashboard(): CleanDashboardAction {
  return {
    type: ActionTypes.CLEAN,
  }
}

export function resize(meta: DashboardMeta, prevGrid?: Grid): DashboardResizeAction {
  return {
    type: ActionTypes.RESIZE,
    payload: { meta, prevGrid }
  };
}

export type Action =
  | DashboardLoadAction
  | DashboardLoadSuccessAction
  | DashboardLoadFailureAction
  | DashboardConnectDocksAction
  | DashboardResizeAction
  | DashboardSaveAction
  | CleanDashboardAction;
