import * as React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { NotificationCoditionInstrument } from '../models/notification';

const NumberInput = ({ name, value, onInputChange }: any) => {
  return (
    <input
      className="form-control"
      name={name}
      onChange={onInputChange}
      type="number"
      value={value}
      required={true}
      min={1}
    />
  );
};

export interface Props {
  config: any;
  onSubmit: (settings: any) => void;
}

export interface State {
  [key: string]: any;
  condition1: {
    checked: boolean;
    amount: number;
  };
  condition2: {
    checked: boolean;
    depth: number;
  };
  condition3: {
    checked: boolean;
    percentage: number;
    minutes: number;
    selectedInstrument: NotificationCoditionInstrument; // all/opened/open and saved/saved
  };
  condition4: {
    checked: boolean;
  };
}

export default class NotificationsConfigFormComponent extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = props.config;
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps(props: Props) {
    this.setState(prevState => {
      return {
        ...prevState,
        ...props.config
      };
    });
  }

  toggleCheckbox(event: any) {
    const state = this.state;
    const target = event.target;
    const value = target.checked;
    const name = target.name;

    this.setState({
      ...state,
      [name]: {
        ...state[name],
        checked: value
      }
    });
  }

  handleInputChange(event: any) {
    const state = this.state;
    const target = event.target;
    let value = target.value;
    const name = target.name.split('.');
    this.setState({
      ...state,
      [name[0]]: {
        ...state[name[0]],
        [name[1]]: value
      }
    });
  }

  handleSelectChange(event: any) {
    const state = this.state;
    const target = event.target;
    const value = target.value;

    this.setState({
      ...state,
      condition3: {
        ...state.condition3,
        selectedInstrument: value
      }
    });
  }

  handleSubmit(event: any) {
    event.preventDefault();
    this.props.onSubmit(this.state);
  }

  render() {
    const form = this.state;
    const options = Object.keys(NotificationCoditionInstrument).reduce(
      (acc: Element[], key: any) => {
        if (isNaN(Number(key))) {
          const el = (
            <option key={key}>
              {I18n.t(`notifications.config.${key.toLowerCase()}`)}
            </option>
          );
          return [...acc, el];
        }
        return acc;
      },
      []
    );
    return (
      <div>
        <p><Translate value="notifications.notifyMe" />:</p>
        <form>
          <div className="col">
            <div className="form-group form-check">
              <input
                type="checkbox"
                name="condition1"
                className="form-check-input"
                checked={form.condition1.checked}
                onChange={this.toggleCheckbox}
              />
              <label className="form-check-label">
                <Translate value="notifications.amount" />
                <NumberInput
                  name={'condition1.amount'}
                  value={form.condition1.amount}
                  onInputChange={this.handleInputChange}
                />
                <Translate value="notifications.amountMultiply" />
              </label>
            </div>
          </div>

          <div className="col">
            <div className="form-group form-check">
              <input
                type="checkbox"
                name="condition2"
                className="form-check-input"
                checked={form.condition2.checked}
                onChange={this.toggleCheckbox}
              />
              <label className="form-check-label">
                <Translate value="notifications.liquidity" />
                <NumberInput
                  name={'condition2.depth'}
                  value={form.condition2.depth}
                  onInputChange={this.handleInputChange}
                />
              </label>
            </div>
          </div>

          <div className="col">
            <div className="form-group form-check">
              <input
                type="checkbox"
                name="condition3"
                className="form-check-input"
                checked={form.condition3.checked}
                onChange={this.toggleCheckbox}
              />
              <label className="form-check-label">
                <Translate value="notifications.priceChange" />
                <NumberInput
                  name={'condition3.percentage'}
                  value={form.condition3.percentage}
                  onInputChange={this.handleInputChange}
                />
                <Translate value="notifications.percentIn" />
                <NumberInput
                  name={'condition3.minutes'}
                  value={form.condition3.minutes}
                  onInputChange={this.handleInputChange}
                />
                <Translate value="notifications.minutes" />
                <select
                  className="form-control select-input-outline"
                  name={'codition3.instruments'}
                  value={form.condition3.selectedInstrument}
                  onChange={this.handleSelectChange}
                >
                  {options.map(o => <>o</>)}
                </select>
                <Translate value="notifications.instruments" />
              </label>
            </div>
          </div>

          <div className="col">
            <div className="form-group form-check">
              <input
                type="checkbox"
                name="condition4"
                className="form-check-input"
                checked={form.condition4.checked}
                onChange={this.toggleCheckbox}
              />
              <label className="form-check-label">
                <Translate value="notifications.emailNotify" />
              </label>
            </div>
          </div>

          <div className="d-flex">
            <button
              className="btn btn-approval ml-auto"
              onClick={this.handleSubmit}
            >
              <Translate value="notifications.save" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}
