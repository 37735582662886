import { DashboardView } from '../models/profile'
import { LogAction } from '../../main/middlewares/logger'
import { LogLevel } from '../../shared/logger/models/logger'
import { I18n } from 'react-redux-i18n'

export enum ActionTypes {
  LOAD = '[View] Load',
  LOAD_SUCCESS = '[View] Load success',
  LOAD_FAILURE = '[View] Load failure',
  SAVE = '[View] Save',
  REMOVE = '[View] Remove',
  LOAD_AVAILABLE_VIEWS = '[View] Load available profiles',
  LOAD_VISIBLE_VIEWS = '[View] Load visible profiles',
  TOGGLE_VISIBILITY = '[View] Set visible/hidden view in sidemenu',
  RESET_ACTIVE_VIEW = '[View] Reset active view',
  SET_PROFILE_VERSION = '[View] Set server profile version',
  SEND_TO_SERVER = '[View] Send profile to server',
  SET_FLEX_LAYOUT = '[View] Set flex layout'
}

export interface ViewLoadAction {
  type: ActionTypes.LOAD
  payload: string
}

export interface ViewLoadSuccessAction {
  type: ActionTypes.LOAD_SUCCESS
  payload: DashboardView
}

export interface ViewLoadFailureAction extends LogAction {
  type: ActionTypes.LOAD_FAILURE
  error: any
}

export interface ViewSaveAction extends LogAction {
  type: ActionTypes.SAVE
  payload: DashboardView
}

export interface ViewRemoveAction extends LogAction {
  type: ActionTypes.REMOVE
  payload: string
}

export interface ViewLoadAvaiableAction {
  type: ActionTypes.LOAD_AVAILABLE_VIEWS
  payload: any[]
}

export interface ViewLoadVisibleAction {
  type: ActionTypes.LOAD_VISIBLE_VIEWS
  payload: string[]
}

export interface ViewToggleVisibilityAction {
  type: ActionTypes.TOGGLE_VISIBILITY
  payload: { viewId: string; isVisible: boolean }
}

export interface ViewResetActiveViewAction {
  type: ActionTypes.RESET_ACTIVE_VIEW
}

export interface SetProfileVersionAction {
  type: ActionTypes.SET_PROFILE_VERSION
  payload: string
}

export interface SetFlexLayoutAction {
  type: ActionTypes.SET_FLEX_LAYOUT
  payload: any
}

export interface SendToServerAction {
  type: ActionTypes.SEND_TO_SERVER;
}

export function load(viewId: string): ViewLoadAction {
  return {
    type: ActionTypes.LOAD,
    payload: viewId,
  }
}

export function loadSuccess(view: DashboardView): ViewLoadSuccessAction {
  return {
    type: ActionTypes.LOAD_SUCCESS,
    payload: view,
  }
}

export function loadFailure(error: any): ViewLoadFailureAction {
  return {
    type: ActionTypes.LOAD_FAILURE,
    error: error,
    logLevel: LogLevel.ERROR,
    logMessage: I18n.t('profile.log.loadFailure'),
  }
}

export function save(view: DashboardView): ViewSaveAction {
  return {
    type: ActionTypes.SAVE,
    payload: view,
    logLevel: LogLevel.INFO,
    logMessage: I18n.t('dashboard.log.savingDashboardName', {
      name: view.name,
      more: I18n.t('sidebar.more'),
    }),
  }
}

export function remove(viewId: string, viewName?: string): ViewRemoveAction {
  return {
    type: ActionTypes.REMOVE,
    payload: viewId,
    logLevel: LogLevel.INFO,
    logMessage: I18n.t('dashboard.log.removingDashboardName', {
      name: viewName,
    }),
  }
}

export function loadAvaiable(views: any[]): ViewLoadAvaiableAction {
  return {
    type: ActionTypes.LOAD_AVAILABLE_VIEWS,
    payload: views,
  }
}

export function loadVisible(visibleViews: string[]): ViewLoadVisibleAction {
  return {
    type: ActionTypes.LOAD_VISIBLE_VIEWS,
    payload: visibleViews,
  }
}

export function toggleVisibility(
  viewId: string,
  isVisible: boolean
): ViewToggleVisibilityAction {
  return {
    type: ActionTypes.TOGGLE_VISIBILITY,
    payload: { viewId, isVisible },
  }
}

export function resetActiveView(): ViewResetActiveViewAction {
  return {
    type: ActionTypes.RESET_ACTIVE_VIEW,
  }
}

export function setProfileVersion(
  profileVersion: string
): SetProfileVersionAction {
  return {
    type: ActionTypes.SET_PROFILE_VERSION,
    payload: profileVersion,
  }
}

export function setFlexLayout(flexLayout: any): SetFlexLayoutAction {
  return {
    type: ActionTypes.SET_FLEX_LAYOUT,
    payload: flexLayout,
  }
}

export function sendToServer(): SendToServerAction {
  return {
    type: ActionTypes.SEND_TO_SERVER
  };
}

export type Action =
  | ViewLoadAction
  | ViewLoadSuccessAction
  | ViewLoadFailureAction
  | ViewSaveAction
  | ViewRemoveAction
  | ViewLoadAvaiableAction
  | ViewToggleVisibilityAction
  | ViewResetActiveViewAction
  | ViewLoadVisibleAction
  | SetProfileVersionAction
  | SendToServerAction
  | SetFlexLayoutAction;
