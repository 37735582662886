import { Action, ActionTypes } from '../actions/table';
import { ITable } from '../models/table';
import { ComponentType } from '../models/component';
import { TradeTable } from '../../../trade/models/tradesTable';
import { OrderTable } from '../../../orders/models/orderTable';
import { OwnTradeTable } from '../../../trade/models/ownTradesTable';
import { RequestTable } from '../../../requests/models/requestTable';
import { LogsTable } from '../../logger/models/logger';
import { BulkTable } from '../../../bulkOrders/models/BulkTable';
import { TradeReportingTable } from '../../../trade/models/tradeReportingTable';
import { PriceAlarmTable } from '../../../priceAlarm/models/priceAlarmTable';
import { VenueTable } from '../../venues/models/venueTable';

export interface State {
  ids: string[];
  entities: { [id: string]: ITable };
}

export const initialState: State = {
  ids: [],
  entities: {}
};

export function reducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case ActionTypes.LOAD: {
      const tables = action.payload;

      const staticTables = state.ids
        .map((id: string) => state.entities[id])
        .filter((table: ITable) => table.id.indexOf('recent-') === 0);

      const newTableEntities = tables.concat(staticTables).reduce(
        (entities: { [id: string]: ITable }, table: ITable) => {
          const customId = (table.id.indexOf('recent-') === 0) ? table.id : undefined;
          let tableObj: any = {...table};
          switch (table.type) {
            case ComponentType.Trade: {
              const tradeTable = new TradeTable(table.id, customId);  
              tableObj = { ...tradeTable, ...{...tableObj, actions: tradeTable.actions, columns: tradeTable.columns } };
              break;
            }
            case ComponentType.Order: {
              const orderTable = new OrderTable(table.id, customId);  
              tableObj = { ...orderTable, ...{...table, actions: orderTable.actions } } ;
              break;
            }
            case ComponentType.Owntrade: {
              const ownOrderTable = new OwnTradeTable(table.id, customId);  
              tableObj = { ...ownOrderTable, ...{...table, actions: ownOrderTable.actions} };
              break;
            }
            case ComponentType.Request: {
              const requestTable = new RequestTable(table.id, customId);  
              tableObj = { ...requestTable, ...{...table, actions: requestTable.actions} };
              break;
            }
            case ComponentType.Log: {
              const logsTable = new LogsTable(table.id, customId); 
              tableObj = { ...logsTable, ...{...table, actions: logsTable.actions, columns: logsTable.columns} };
              break;
            }
            case ComponentType.BulkOrder: {
              const bulkTable = new BulkTable(table.id, customId); 
              tableObj = { ...bulkTable, ...{...table, actions: bulkTable.actions} };
              break;
            }
            case ComponentType.TradeReport: {
              const tradeReportTable = new TradeReportingTable(table.id, customId);  
              tableObj = { ...tradeReportTable, ...{...table, actions: tradeReportTable.actions, columns: tradeReportTable.columns} };
              break;
            }
            case ComponentType.PriceAlarm: {
              const priceAlarmTable = new PriceAlarmTable(table.id, customId);  
              tableObj = { ...priceAlarmTable, ...{...table, actions: priceAlarmTable.actions, columns: priceAlarmTable.columns} };
              break;
            }
            case ComponentType.Venues: {
              const venueTable = new VenueTable(table.id, customId);  
              tableObj = { ...venueTable, ...{...table, actions: venueTable.actions} };
              break;
            }
            default:
              break;
          }

          return Object.assign(entities, {
            [table.id]: tableObj
          });
        },
        {}
      );

      return {
        ...state,
        ids: Object.keys(newTableEntities),
        entities: newTableEntities
      };
    }

    case ActionTypes.CREATE: {
      const tables: ITable[] = action.payload;
      const newTables = tables.filter(table => !state.entities[table.id]);
      const newTableIds = newTables.map(table => table.id);

      const newTableEntities = newTables.reduce(
        (entities: { [id: string]: ITable }, table: ITable) => {
          return Object.assign(entities, {
            [table.id]: table
          });
        },
        {}
      );

      return {
        ...state,
        ids: [...state.ids, ...newTableIds],
        entities: Object.assign({}, state.entities, newTableEntities)
      };
    }

    case ActionTypes.SORT: {
      const { id, sorting } = action.payload;
      return {
        ...state,
        entities: {
          ...state.entities,
          [id]: {
            ...state.entities[id],
            sorting
          }
        }
      };
    }

    case ActionTypes.TRIGGER_COLUMN_NAMES: {
      const { id, hiddenColumnNames } = action.payload;
      const sorting = state.entities[id].sorting.filter(sort => hiddenColumnNames.indexOf(sort.columnName) === -1);
      const newState = Object.assign({}, state, {
        entities: Object.assign({}, state.entities, {
          [id]: Object.assign({}, state.entities[id], {
            hiddenColumnNames: hiddenColumnNames,
            sorting
          })
        })
      });

      return { ...state, ...newState };
    }

    case ActionTypes.TRIGGER_HEADLINES: {
      const { id, showHeadlines } = action.payload;
      const newState = Object.assign({}, state, {
        entities: Object.assign({}, state.entities, {
          [id]: Object.assign({}, state.entities[id], {
            showHeadlines: showHeadlines
          })
        })
      });

      return { ...state, ...newState };
    }

    case ActionTypes.TRIGGER_TABLE_SPECIFIC_FILTER: {
      const { id, filter, enable } = action.payload;
      const newState = Object.assign({}, state, {
        entities: Object.assign({}, state.entities, {
          [id]: Object.assign({}, state.entities[id], {
            filters: Object.assign({}, state.entities[id].filters, {
              [filter]: enable
            })
          })
        })
      });

      return { ...state, ...newState };
    }

    case ActionTypes.TRIGGER_SHOW_HIDDEN: {
      const { id, showingHidden } = action.payload;
      const newState = Object.assign({}, state, {
        entities: Object.assign({}, state.entities, {
          [id]: Object.assign({}, state.entities[id], {
            showingHidden: showingHidden
          })
        })
      });

      return { ...state, ...newState };
    }

    case ActionTypes.HIDE_ROWS: {
      const { id, hiddenRows } = action.payload;
      const newState = Object.assign({}, state, {
        entities: Object.assign({}, state.entities, {
          [id]: Object.assign({}, state.entities[id], {
            hiddenRows: [...state.entities[id].hiddenRows, ...hiddenRows]
          })
        })
      });

      return { ...state, ...newState };
    }

    case ActionTypes.SHOW_ROWS: {
      const { id, rows } = action.payload;
      const newState = Object.assign({}, state, {
        entities: Object.assign({}, state.entities, {
          [id]: Object.assign({}, state.entities[id], {
            hiddenRows: state.entities[id].hiddenRows.filter(
              row => rows.indexOf(row) === -1
            )
          })
        })
      });

      return { ...state, ...newState };
    }

    case ActionTypes.UNPIN_ROWS: {
      const { id, rows } = action.payload;
      const newState = Object.assign({}, state, {
        entities: Object.assign({}, state.entities, {
          [id]: Object.assign({}, state.entities[id], {
            pinnedRows: state.entities[id].pinnedRows.filter(
              row => rows.indexOf(row) === -1
            )
          })
        })
      });

      return { ...state, ...newState };
    }

    case ActionTypes.PIN_ROWS: {
      const { id, pinnedRows } = action.payload;
      const newState = Object.assign({}, state, {
        entities: Object.assign({}, state.entities, {
          [id]: Object.assign({}, state.entities[id], {
            pinnedRows: [...state.entities[id].pinnedRows, ...pinnedRows]
          })
        })
      });

      return { ...state, ...newState };
    }

    case ActionTypes.TRIGGER_SEARCH_TAGS: {
      const { id, tags } = action.payload;
      const newState = Object.assign({}, state, {
        entities: Object.assign({}, state.entities, {
          [id]: Object.assign({}, state.entities[id], {
            tags: tags
          })
        })
      });

      return { ...state, ...newState };
    }

    case ActionTypes.REMOVE: {
      const tableId = action.payload;
      const newTableIds = state.ids.filter(id => id !== tableId);
      const newTableEntities = newTableIds.reduce(
        (entities: { [id: string]: ITable }, id: string) => {
          return Object.assign(entities, {
            [id]: state.entities[id]
          });
        },
        {}
      );

      return {
        ...state,
        ids: newTableIds,
        entities: newTableEntities
      };
    }

    case ActionTypes.SET_COLUMN_WIDTH: {
      const {tableId, name, width} = action.payload;
      const table = {...state.entities[tableId]};
      
      table.columns = table.columns.map(c => {
        if (c.name === name) {
          c.width = width;
        }
        return c;
      });
      const newEntities = {...state.entities};
      
      newEntities[tableId] = table;
      return {
        ...state,
        entities: newEntities
      };
    }

    case ActionTypes.SET_COLUMNS: {
      const {columns, tableId} = action.payload;
      const table = {...state.entities[tableId]};
      
      table.columns = columns
      const newEntities = {...state.entities};
      
      newEntities[tableId] = table;
      return {
        ...state,
        entities: newEntities
      };
    }

    default:
      return state;
    }
}
