import React, { useState, useEffect } from 'react'

import { connect } from 'react-redux'
import { State } from 'js/main/reducers/rootReducer'
import { logout } from 'js/authentication/actions/authentication'
import { I18n, Translate } from 'react-redux-i18n'
import SidebarContextMenu from './SidebarContextMenu'
import { config } from 'js/main/config'
import withAutoClose from 'js/shared/autoclose/components/Autoclose'
import { saveClient } from 'js/shared/settings/actions/settings'
import {
  isBasketOrderUploadEnabled,
  isQuoteRequestEnabled,
} from 'js/requests/selectors/requests'
import RecentActions from 'js/dashboard/components/recentActions'
import { MarketsheetSelect } from 'js/shared/marketsheetSelect/containers/marketsheetSelect'
import FavoriteSelect from 'js/shared/favorite/containers/favoriteSelect'
import ProfileViews from 'js/dashboard/components/profileViews'
import { createNotification } from 'js/shared/notifications/actions/notifications'
import {
  INotification,
  NotificationType,
} from 'js/shared/notifications/models/notification'
import { v1 } from 'uuid'
import {
  isAtLeastOneConfigEnabled,
  isTradeReportingUser,
  isPasswordExpired,
  isProfileModificationAllowed,
} from 'js/authentication/selectors/authetication'
import { MemoTranslate } from 'js/shared/i18n/components/memoTranslate'
import QuoteRequestModal from 'js/requests/components/QuoteRequestModalRefactored'
import { resize } from 'js/dashboard/actions/dashboard'
import PriceAlarmModal from 'js/priceAlarm/components/priceAlarmModal'
import { getSSOExpiration } from 'js/authentication/selectors/connection'
import { getSidebarIsLocked } from 'js/shared/settings/selectors/selector'
import { ITab } from 'js/shared/ui/models/tab'
import {
  DashboardComponent,
  DashboardMeta,
} from 'js/dashboard/models/dashboard'
import { IFavorite } from 'js/shared/favorite/models/favorite'
import { getFormattedTime } from 'js/shared/utils/formatters'
import { ComponentType } from 'js/shared/ui/models/component'
import SettingsConfigModal from 'js/shared/settings/components/SettingsConfigModal';

const sidebarWidth = 337

interface SidebarProps {
  locked: boolean
  quoteRequestsEnabled: boolean
  bulkOrderUploadEnabled: boolean
  atLeastOneSettingEnabled: boolean
  passwordExpired: boolean
  tradeReportingUser: boolean
  expanded: boolean // from autoclose
  triggerVisibility: (visible: boolean) => void // from autoclose
  createNotification: (notification: INotification) => void
  saveSidebar: (sidebar: { sidebar: { locked: boolean } }) => void
  logout: () => void
  onTabCreate: (tab: ITab) => void
  onRecentActionsDrag: (
    e: React.MouseEvent,
    components: { type: ComponentType; title: string }[]
  ) => void
  onSingleDragFromSidemenu: (
    e: React.MouseEvent,
    component: DashboardComponent
  ) => void
  onFavoriteSelectDrag: (e: React.MouseEvent, favorite: IFavorite) => void
  dashboardResize: (dashboardMeta: DashboardMeta) => void
  ssoExpiration: number
  ssoLatestToken: string
  modificationEnabled: boolean
  onAddDragMouseDown?: (
    e: React.MouseEvent,
    component: DashboardComponent
  ) => void
}

const SidebarComponent = React.forwardRef<HTMLDivElement, SidebarProps>(
  (props, ref) => {
    const [ssoCountdown, setSsoCountdown] = useState(0)
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);

    const handleSettingsModalOpen = () =>  setSettingsModalOpen(true);

    const {
      ssoExpiration,
      expanded,
      dashboardResize,
      createNotification,
      triggerVisibility,
      locked,
      saveSidebar,
      bulkOrderUploadEnabled,
      atLeastOneSettingEnabled,
      passwordExpired,
      onTabCreate,
      quoteRequestsEnabled,
      onRecentActionsDrag,
      tradeReportingUser,
      onSingleDragFromSidemenu,
      onFavoriteSelectDrag,
      logout,
      modificationEnabled,
    } = props

    useEffect(() => {
      let timer
      if (config.ssoTokenCountdown) {
        timer = setInterval(() => {
          const timer = Math.floor(
            (ssoExpiration - new Date().getTime()) / 1000
          )
          setSsoCountdown(isNaN(timer) ? 0 : timer)
        }, 1000)
      }
      return () => clearInterval(timer)
    }, [ssoExpiration])

    const handleTriggerVisibility = (locked, expanded) => {
      if (locked) {
        createNotification({
          id: v1(),
          type: NotificationType.INFO,
          message: I18n.t('sidebar.closeLockedError'),
        })
      } else {
        triggerVisibility(!expanded)
      }
    }

    const logoName = config.branding.logoSidebar
    const logo = `${config.subfolder}/logo/${logoName}`
    return (
      <React.Fragment>
        <div
          className={expanded ? 'sidebar' : 'sidebar hidden'}
          style={{ width: `${sidebarWidth}px`, zIndex: 25 }}
          ref={ref}
        >
          <div
            className={expanded ? 'threebar cross' : 'threebar bar'}
            title={I18n.t(expanded ? 'sidebar.close' : 'sidebar.menu')}
            onClick={() => handleTriggerVisibility(locked, expanded)}
          >
            <div className="bar" />
            <div className="bar" />
            <div className="bar" />
          </div>
          <div className="innerSidebar">
            <div className="sidebar__header d-flex">
              <div className="title">
                <MemoTranslate value="sidebar.menu" tag="h1" />
              </div>
              <div className="icons-list ml-auto d-flex align-items-center">
                {atLeastOneSettingEnabled && (
                  <div>
                    <i
                      className={`oi oi-cog oi-empty white ${settingsModalOpen ? 'active' : 'white'}`}
                      title={I18n.t('sidebar.settings')}
                      onClick={handleSettingsModalOpen}
                    />
                  </div>
                )}
                <div>
                  <a href={config.serverURL + '/doc'} target="_blank">
                    <i
                      className={'oi oi-question-mark oi-empty white'}
                        title={I18n.t('sidebar.documentation')}
                    />
                  </a>
                </div>
                <div>
                  <i
                    className={`oi oi-pin white ${locked ? 'active' : 'white'}`}
                    title={I18n.t(locked ? 'sidebar.unlock' : 'sidebar.lock')}
                    onClick={() =>
                      saveSidebar({sidebar: {locked: !locked}})
                    }
                  />
                </div>
                <div className="align-self-center ml-n2">
                  <SidebarContextMenu
                    bulkOrderUploadEnabled={bulkOrderUploadEnabled}
                    passwordExpired={passwordExpired}
                  />
                </div>
              </div>
            </div>
            <div className="sidebar__content">
              <div className="sidebar__topic">
                <RecentActions
                  tabCreate={onTabCreate}
                  quoteRequestsEnabled={quoteRequestsEnabled}
                  onDragActions={onRecentActionsDrag}
                  isTradeReportingUser={tradeReportingUser}
                />
              </div>
              <div className="sidebar__topic">
                <MarketsheetSelect
                  onSelectComponent={(e, component) => {
                    onSingleDragFromSidemenu(e, component)
                    triggerVisibility(locked)
                  }}
                />
              </div>

              <div className="sidebar__topic">
                <FavoriteSelect onSelectFavorite={onFavoriteSelectDrag} />
              </div>

              <div className="sidebar__topic" hidden={!modificationEnabled}>
                <ProfileViews />
              </div>
            </div>
            <div className="sidebar__topic logout">
              <div>
                <span
                  hidden={!config.ssoTokenCountdown}
                  className="countdown"
                  title={I18n.t('sidebar.ssoCountDown')}
                >
                  {getFormattedTime(ssoCountdown)}
                </span>
                <a className="logout-text" onClick={() => logout()}>
                  <Translate value="sidebar.logout" />
                </a>
                <a
                  className="oi oi-account-logout active"
                  onClick={() => logout()}
                  title={I18n.t('sidebar.logout')}
                  data-test="logout-button"
                />
              </div>
            </div>
            <div className="sidebar__footer">
              <div className="sidebar__topic d-flex justify-content-between align-items-start">
                <div>
                  <img className="logo" src={logo} alt="Logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <QuoteRequestModal sideBarVisible={expanded} />

        {settingsModalOpen && (
          <SettingsConfigModal
            closeModal={() => setSettingsModalOpen(false)}
            modalVisible={settingsModalOpen}
          />
        )}
      </React.Fragment>
    )
  }
)

const mapStateToProps = (state: State) => ({
  locked: getSidebarIsLocked(state),
  quoteRequestsEnabled: isQuoteRequestEnabled(state),
  bulkOrderUploadEnabled: isBasketOrderUploadEnabled(state),
  atLeastOneSettingEnabled: isAtLeastOneConfigEnabled(state),
  tradeReportingUser: isTradeReportingUser(state),
  passwordExpired: isPasswordExpired(state),
  ssoExpiration: getSSOExpiration(state),
  modificationEnabled: isProfileModificationAllowed(state),
})

const mapDispatchToProps = {
  createNotification: createNotification,
  saveSidebar: saveClient,
  logout: logout,
  dashboardResize: resize,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAutoClose(SidebarComponent))
