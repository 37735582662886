import { IComponent, ComponentType } from './component'
import { v1 } from 'uuid'
import { ContractMatrix } from '../../../orderbook/models/orderbooks'
import { isObject } from 'util'
import { OhlcPeriod } from '../../../orderbook/models/charts'

export interface IChart extends IComponent {
  dockId: string
  contractMatrix?: any
  priority: number
  layout?: any
  currentTheme?: string
}

export class Chart implements IChart {
  id: string
  dockId: string
  priority: number = 1
  title: string
  type: ComponentType
  itemId: string
  instrumentId: string
  selectedPeriodType: string
  contractId: string
  groupTypes: ('bidPrices' | 'askPrices' | 'lastPrices')[]
  contractMatrix: ContractMatrix
  ohlcPeriod: OhlcPeriod
  syntheticContractId?: string
  layout?: any
  constructor(
    dockId: string,
    title: string,
    itemId?: any,
    instrumentId?: any,
    selectedPeriodType?: string,
    contractId?: string,
    groupTypes?: ('bidPrices' | 'askPrices' | 'lastPrices')[]
  ) {
    this.id = v1()
    this.dockId = dockId
    this.title = title
    this.type = ComponentType.MarketChart
    this.itemId = itemId ? (isObject(itemId) ? itemId.id : itemId) : ''
    this.instrumentId = instrumentId
      ? typeof instrumentId === 'object'
        ? instrumentId.id
        : instrumentId
      : ''
    this.contractId = contractId ? contractId : ''
    this.selectedPeriodType = selectedPeriodType ? selectedPeriodType : ''
    this.groupTypes = groupTypes ? groupTypes : []
  }
}
