import { INotification, NotificationResponse } from '../models/notification';
import { LogAction } from '../../../main/middlewares/logger';
import { I18n } from 'react-redux-i18n';
import { convertNotificationTypeToLogLevel } from '../../logger/helper/logger';

export enum ActionTypes {
  SAVE_CONFIG = '[Notifications] Save config',
  LOAD_CONFIG = '[Notifications] Load config',
  CREATE = '[Notification] Create',
  REMOVE = '[Notification] Remove',
  SERVER_NOTIFICATION_RECEIVED = '[Server Notification] Server notification received',
}

export interface NotificationLoadConfigAction {
  type: ActionTypes.LOAD_CONFIG;
  payload: any;
}

export interface NotificationSaveConfigAction {
  type: ActionTypes.SAVE_CONFIG;
  payload: any;
}

export interface NotificationCreateAction {
  type: ActionTypes.CREATE;
  payload: INotification;
}

export interface NotificationRemoveAction {
  type: ActionTypes.REMOVE;
  payload: string;
}

export interface AddLogInformationToServerNotification extends LogAction {
  type: ActionTypes.SERVER_NOTIFICATION_RECEIVED;
  payload: any;
}

export function saveConfig(config: any): NotificationSaveConfigAction {
  return {
    type: ActionTypes.SAVE_CONFIG,
    payload: config
  };
}

export function loadConfig(config: any): NotificationLoadConfigAction {
  return {
    type: ActionTypes.LOAD_CONFIG,
    payload: config
  };
}

export function createNotification(
  notification: INotification
): NotificationCreateAction {
  return {
    type: ActionTypes.CREATE,
    payload: notification
  };
}

export function removeNotification(id: string): NotificationRemoveAction {
  return {
    type: ActionTypes.REMOVE,
    payload: id
  };
}
export function addLogInformationToServerNotification(content: NotificationResponse): AddLogInformationToServerNotification {
  let message = '';
  if (content.translationParams) {
    message = I18n.t(content.message, 
      content.translationParams  
    );
  } else {
    message = I18n.t(content.message);
  }
  return {
    type: ActionTypes.SERVER_NOTIFICATION_RECEIVED,
    payload: content,
    logLevel: convertNotificationTypeToLogLevel(content.notificationType),
    logMessage: content.translate ? content.message : message
  };
}

export type Action =
  | NotificationLoadConfigAction
  | NotificationSaveConfigAction
  | NotificationCreateAction
  | NotificationRemoveAction;