/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/no-direct-mutation-state */
import '../../../shared/utils/wdyr'
import * as React from 'react'
import 'chartiq/js/advanced'
import Chart, { CIQ } from '@chartiq/react-components/Chart/Advanced'
import '../scripts/components'
import {catchError, map} from 'rxjs/operators'
import {Chart as EwtChart} from '../../ui/models/chart'
import {
  convertToChartIqInitialData,
  convertToChartIqUpdateData,
  getPeriodSeconds,
  adjustDOM,
  setupNameValueStore,
  getWords,
} from '../helpers/chartIQHelper'
import '../styles/estar.css'
import ChartService from '../../../orderbook/services/charting'
import store from '../../../main/store/store'
import {
  chartSetDrawings,
  chartSetLayout,
  chartUnsubscribe,
  setContract,
} from '../../ui/actions/chart'
import { Contract, ContractState, Product } from '../../../orderbook/models/contracts'
import { OhlcPeriod, PartialUpdateChartDataResponse } from '../../../orderbook/models/charts'
import { v1 } from 'uuid'
import {of, Subscription} from 'rxjs'
import { config } from '../../../main/config'
import { Debouncer } from '../../utils/components/debounce'
import { getDockTitlesMap } from '../../../dashboard/selectors/dashboard'
import { createMasterDataIdString, createMasterDataSyntheticIdString, createSyntheticMasterDataId } from '../../../orderbook/selectors/contracts'
import {
  convertSyntheticIdToConcreteId,
  convertSyntheticToConcrete,
  convertToSyntheticContractId,
  isSynthetic,
} from '../../ui/components/chart/helper/helper'

interface ChartProps {
  activity: boolean
  dockId: string
  siblings: number
  setOhlcPeriod: (contractId: string, period: number, dockId: string) => void
  chart: EwtChart
  contracts: { [key: string]: Contract }
  products: {[key: string]: Product}
  contractIds: string[]
  contractNameToIdMap: { [name: string]: string }
  drawings: { [contractName: string]: any }
  onChartSetContract: (
    chartId: string,
    contractId: string,
    dockId?: string,
    ohlcPeriod?: OhlcPeriod
  ) => void
}

interface ChartState {
  visibility: {
    volume: boolean
  }
  savedCandleWidth: number
  hasError: boolean
  elemHeight: number
  quoteFeed: any
  chartEngine: any
  nameValueStore: any
  chartConfig: any
  chartReady: boolean
}

export default class ChartIQComponent extends React.Component<
  ChartProps,
  ChartState
> {

  private chartService = new ChartService()
  private chartRef
  private subscriptions: Subscription[]
  constructor(props: ChartProps) {
    super(props)
    this.chartRef = React.createRef()
    this.chartService = new ChartService()
    const nameValueStore = setupNameValueStore(CIQ, props)

    const contractName = this._getContractName(props)
    this.state = {
      visibility: {
        volume: true,
      },
      savedCandleWidth: undefined,
      hasError: false,
      elemHeight: 100,
      quoteFeed: {},
      chartEngine: undefined,
      nameValueStore: nameValueStore,
      chartConfig: {
        chartId: 'chart' + v1(),
        initialSymbol: {
          symbol: contractName,
          name: null,
          exchDisp: 'IM',
        },
        themes: {
          defaultTheme: config.theme === 'theme-dark' ? 'DefaultDark' : 'day',
        },
        onEngineReady: this.onEngineReady,
        onChartReady: this.onChartReady,
        nameValueStore: nameValueStore,
        chartEngineParams: {
          layout: {
            crosshair: props.chart?.layout?.crosshair === false ? false : true,
            periodicity: props.chart?.layout?.periodicity || 1,
            interval: props.chart?.layout?.interval || 1,
            // weird setting of longer periods, where timeUnit has to be null and interval is a string
            timeUnit: ['day', 'month', 'week'].includes(props.chart?.layout?.interval)
              ? null
              : (props.chart?.layout?.timeUnit || 'hour'),
          },
        },
        enabledAddOns: {
          fullScreen: true,
        },
        menuPeriodicity: [
          {
            type: 'item',
            label: '1 D',
            cmd: "Layout.setPeriodicity(1,1,'day')",
            value: { period: 1, interval: 1, timeUnit: 'day' },
          },
          {
            type: 'item',
            label: '1 W',
            cmd: "Layout.setPeriodicity(1,1,'week')",
            value: { period: 1, interval: 1, timeUnit: 'week' },
          },
          {
            type: 'item',
            label: '1 Mo',
            cmd: "Layout.setPeriodicity(1,1,'month')",
            value: { period: 1, interval: 1, timeUnit: 'month' },
          },
          { type: 'separator' },
          {
            type: 'item',
            label: '1 Min',
            cmd: "Layout.setPeriodicity(1,1,'minute')",
            value: { period: 1, interval: 1, timeUnit: 'minute' },
          },
          {
            type: 'item',
            label: '5 Min',
            cmd: "Layout.setPeriodicity(1,5,'minute')",
            value: { period: 1, interval: 5, timeUnit: 'minute' },
          },
          {
            type: 'item',
            label: '10 Min',
            cmd: "Layout.setPeriodicity(2,5,'minute')",
            value: { period: 2, interval: 5, timeUnit: 'minute' },
          },
          {
            type: 'item',
            label: '15 Min',
            cmd: "Layout.setPeriodicity(1,15,'minute')",
            value: { period: 1, interval: 15, timeUnit: 'minute' },
          },
          {
            type: 'item',
            label: '30 Min',
            cmd: "Layout.setPeriodicity(2,15,'minute')",
            value: { period: 2, interval: 15, timeUnit: 'minute' },
          },
          {
            type: 'item',
            label: '1 Hour',
            cmd: "Layout.setPeriodicity(1,1,'hour')",
            value: { period: 1, interval: 1, timeUnit: 'hour' },
          },
          {
            type: 'item',
            label: '4 Hour',
            cmd: "Layout.setPeriodicity(4,1,'hour')",
            value: { period: 4, interval: 1, timeUnit: 'hour' },
          },
          { type: 'separator' },
          {
            type: 'item',
            label: '1 Sec',
            cmd: "Layout.setPeriodicity(1,1,'second')",
            value: { period: 1, interval: 1, timeUnit: 'second' },
          },
          {
            type: 'item',
            label: '10 Sec',
            cmd: "Layout.setPeriodicity(1,10,'second')",
            value: { period: 1, interval: 10, timeUnit: 'second' },
          },
          {
            type: 'item',
            label: '30 Sec',
            cmd: "Layout.setPeriodicity(1,30,'second')",
            value: { period: 1, interval: 30, timeUnit: 'second' },
          },
          { type: 'separator' },
          {
            type: 'item',
            label: 'Tick',
            cmd: "Layout.setPeriodicity(1,1,'tick')",
            value: { period: 1, timeUnit: 'tick' },
          },
        ],
      },
      chartReady: false,
    }
    this.subscriptions = []
  }

  onEngineReady = (engine) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        chartEngine: engine,
      }
    })
    if (
      engine.layout.studies &&
      Object.keys(engine.layout.studies)
        .map((name) => engine.layout.studies[name])
        .filter((study) => study.type === 'volume').length === 0
    ) {
      CIQ.Studies.addStudy(engine, 'volume')
    }
    engine.addEventListener('newChart', this.newChartListener)
    engine.addEventListener('drawing', this.drawingListener)
  }

  onChartReady = (chartEngine) => {
    const restoreLayout = this.props.chart.layout
    const chartId = this.props.chart.id
    if (restoreLayout) {
      chartEngine.importLayout(restoreLayout, {
        managePeriodicity: true,
        preserveTicksAndCandleWidth: true,
        noDataLoad: true
      })
    } else {
      store.dispatch(chartSetLayout(chartId, chartEngine.exportLayout(true)))
    }
    const layoutListener = function (parameters) {
      store.dispatch(chartSetLayout(chartId, chartEngine.exportLayout(true)))
    }
    chartEngine.addEventListener('layout', layoutListener)
    this.setState((prevState) => {
      return {
        ...prevState,
        chartReady: true,
      }
    })
    adjustDOM()
  }

  newChartListener = (parameters) => {
    const currentContractId = this.props.chart?.contractId
    const newContractId = this.getContractIdFromName(parameters.symbol)

    const dockTitles = getDockTitlesMap(store.getState())
    const dockTitle = dockTitles[this.props.dockId]
    // checks if the tab (dock) title is correct
    if ((dockTitle && dockTitle !== parameters.symbol) || (newContractId !== currentContractId)) {
      store.dispatch(
        setContract(
          this.props.chart.id,
          this.props.contracts[newContractId] as Contract,
          this.props.dockId
        )
      )
    }
  }

  drawingListener = (parameters) => {
    const drawings = this.state.chartEngine.exportDrawings()
    store.dispatch(chartSetDrawings(parameters.symbol, drawings))
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const allInitialized = !!(
      this.state.chartEngine &&
      this.props?.contracts &&
      this.props.chart &&
      this.props.contracts[this.props.chart.contractId]
    )
    if (allInitialized) {
      // TODO check if drawings in prevProps are equals to drawings in this.props. If yes, do not applyDrawings
      if (this.props?.drawings && this.state.chartReady) {
        const drawings =
          this.props.drawings[
            this.getContractNameFromId(this.props?.chart?.contractId)
            ]
        if (drawings) {
          this.applyDrawings(this.state, drawings)
        }
      }
    }
    this._contractRolling();
  }

  _contractRolling = () => {
    const { chart, contracts } = this.props
    if (!chart.syntheticContractId) {
      const contract: Contract = contracts[chart.contractId] as Contract;
      if (
        !contract
        || contract.idString.localeCompare(chart.contractId) !== 0
        || contract.state === ContractState.INACTIVE
      ) {
        if (this.props.onChartSetContract) {
          let synId = ''
          if (!chart.selectedPeriodType) {
            const product: Product = Object.keys(this.props.products)
              .map(id => this.props.products[id])
              .find(p => createMasterDataIdString(p.instrumentId) === chart.itemId)
            synId = createMasterDataSyntheticIdString(createSyntheticMasterDataId(product.id, 1))
          } else {
            synId = convertToSyntheticContractId(chart.selectedPeriodType, chart.instrumentId)
          }
          const contractId = convertSyntheticIdToConcreteId(synId)
          if (contractId) {
            this.props.onChartSetContract(
              chart.id,
              contractId,
              chart.dockId,
              chart.ohlcPeriod
            )
          }
        }
      }
    }
  }

  getContractIdFromName = (name: string) => {
    let id = undefined
    if (this.props?.contractNameToIdMap) {
      id = this.props?.contractNameToIdMap[name]
    }
    return id
  }

  getContractNameFromId = (id: string) => {
    if (id) {
      return this.props.contracts[id]?.nameWithVenue
    }
    return undefined
  }

  applyDrawings = (state, drawings) => {
    if (state.chartEngine) {
      try {
        state.chartEngine.removeEventListener('drawing', this.drawingListener)
        state.chartEngine.clearDrawings(true, true)
        state.chartEngine.importDrawings(drawings)
        state.chartEngine.draw()
        state.chartEngine.addEventListener('drawing', this.drawingListener)
      } catch (e) {
        console.error(e)
      }
    }
  }

  subscribeInquiry = (symbol, startDate, endDate, params, cb, initial) => {
    let contractId = this.props.contractNameToIdMap[symbol]
    const periodSeconds = getPeriodSeconds(params.period, params.interval)
    const subscription = this.chartService
      .inquireChartData({
        contract: contractId,
        period: periodSeconds,
        chartId: this.state.chartConfig.chartId,
        startDate: startDate,
        endDate: endDate,
        ticks: params.ticks,
        initial
      })
      .pipe(
        map((response: any) => {
          if (!!response.data[contractId] && response.data[contractId].trades?.ohlc?.length === 0 && response.data[contractId].dataBefore) {
            const threeDaysBack = new Date()
            threeDaysBack.setDate(startDate.getDate() - 3)
            // from chartIQ docs: This will preserve that date to be used as the starting point for the next pagination request.
            cb({quotes: [{DT:threeDaysBack}], moreAvailable: true})
          }

          cb(
            !!response.data[contractId]
              ? convertToChartIqInitialData(
                response.data[contractId],
                params.interval,
                periodSeconds
              )
              : {quotes: [], moreAvailable: false}
          )
        }) , catchError( e => {
          if (e.status === 504) {
            // 504 = Gateway timeout
            // setPeriodicity triggers fetchInitialData
            this.state.chartEngine.setPeriodicity({interval: params.interval, period: params.period})
          }
          return of()
        })
      )
      .subscribe();
    this.subscriptions.push(subscription);
  }

  componentDidMount() {
    this.state.quoteFeed.fetchInitialData = (
      symbol,
      startDate,
      endDate,
      params,
      cb
    ) => {
      this.subscribeInquiry(symbol, startDate, endDate, params, cb, true);
    }
    this.state.quoteFeed.fetchPaginationData = (
      symbol,
      startDate,
      endDate,
      params,
      cb
    ) => {
      this.subscribeInquiry(symbol, startDate, endDate, params, cb, false);
    }

    this.state.quoteFeed.subscribe = (symbolObject) => {
      const contractId = this.props.contractNameToIdMap[symbolObject.symbol]
      const periodSeconds = getPeriodSeconds(
        symbolObject.period,
        symbolObject.interval
      )
      if (contractId) {
        if (this.subscriptions.length === 0) {
          // if chart was initialized with empty symbol (dragging from sidebar), it is not subscribed and we force reload
          this.state.chartEngine.setPeriodicity({interval: symbolObject.interval, period: symbolObject.period})
        }

        const subscription = this.chartService
          .subscribeChart(contractId, periodSeconds)
          .pipe(
            map((data: PartialUpdateChartDataResponse) => {
              if (data?.aggregates) {
                const contract = this.props.contracts[data.contract]
                if (
                  data.periodSeconds ==
                  getPeriodSeconds(
                    this.state.chartEngine.getPeriodicity().interval,
                    this.state.chartEngine.getPeriodicity().timeUnit
                  )
                ) {
                  if (
                    contract?.nameWithVenue ===
                    this.getContractNameFromId(this.props?.chart?.contractId)
                  ) {
                    const updates = convertToChartIqUpdateData(
                      data.aggregates,
                      data.periodSeconds
                    )
                    this.state?.chartEngine?.updateChartData(updates, null, {
                      allowReplaceOHL: true,
                    })
                  } else {
                    this.state?.chartEngine?.updateChartData(
                      convertToChartIqUpdateData(
                        data.aggregates,
                        data.periodSeconds
                      ),
                      null,
                      {
                        secondarySeries: contract?.nameWithVenue,
                        allowReplaceOHL: true,
                      }
                    )
                  }
                }
              }
            })
          )
          .subscribe()
        this.subscriptions.push(subscription)
      }
    }

    this.state.quoteFeed.unsubscribe = (symbolObject) => {
      const periodSeconds = getPeriodSeconds(
        symbolObject.period,
        symbolObject.interval
      )
      store.dispatch(
        chartUnsubscribe(
          this.props.contractNameToIdMap[symbolObject.symbol],
          periodSeconds,
          this.state.chartConfig.chartId
        )
      )
    }

    const props = this.props
    const debouncer: Debouncer = new Debouncer()
    CIQ.ChartEngine.Driver.Lookup.ChartIQ.prototype.acceptText = function (
      text,
      filter,
      maxResults,
      cb
    ) {
      debouncer.debounce(() => {
        const words = getWords(text)
        cb(
          props.contractIds
            .map(id => {
              const c: Contract = props.contracts[id]
              return c
                ? {
                    displayName: c.nameWithVenue,
                    name: c.nameWithVenue.toLowerCase(),
                    venue: c.id.venue
                  }
                : undefined
            })
            .filter((contractInfo: {name: string, venue: string}) => {
                return contractInfo
                  ? words.reduce((res: boolean, w: string) => {
                      res =
                        res &&
                        contractInfo.name.toLowerCase()
                          .indexOf(w.toLowerCase()) > -1
                      return res
                    }, true)
                  : false
              }
            )
            .sort((a, b) => {
              let idx1 = a.name.toLowerCase().indexOf(text.toLowerCase())
              let idx2 = b.name.toLowerCase().indexOf(text.toLowerCase())
              //contracts that contain the serarch term but not start with it have the same piority, as in EET
              if (idx1 > 0) idx1 = 1
              if (idx2 > 0) idx2 = 1
              if (idx1 < 0) idx1 = 9999
              if (idx2 < 0) idx2 = 9999
              if (idx1 !== idx2) {
                return idx1 - idx2
              }
              return b > a ? -1 : 1
            })
            .slice(0, 100)
            .map((info) => ({
              display: [
                info.displayName,
                null,
                info.venue,
              ],
              data: {
                symbol: info.displayName,
                name: null,
                exchange: info.venue,
              },
            }))
        )
      }, 500)
    }
    this.observeResizing();
  }

  observeResizing = () => {
    const target = document.getElementById(this.props.chart.id)
    const observer = new ResizeObserver(() => {
      // detect chart was hidden (e.g. by flex layout)
      if (target.clientWidth === 0) {
        this.setState((prevState) => ({
          ...prevState,
          savedCandleWidth: this.props.chart.layout?.candleWidth
        }))
      } else {
        if (this.state.savedCandleWidth) {
          // restore original candle width after chart resizes back to original width
          // execute with delay after chartIQ automatically sets incorrect width
          setTimeout(() => {
            this.state.chartEngine.setCandleWidth(this.state.savedCandleWidth)
            this.state.chartEngine.importLayout(this.props.chart.layout, {
              managePeriodicity: true,
              preserveTicksAndCandleWidth: true,
              noDataLoad: true
            })
            this.state.chartEngine.draw()
            this.setState((prevState) => ({
              ...prevState,
              savedCandleWidth: undefined
            }))
          }, 500)
        }
      }
    });
    observer.observe(target, );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe()
    })
    if (this.state.chartEngine) {
      const periodSeconds = getPeriodSeconds(
        this.state.chartEngine.getPeriodicity().interval,
        this.state.chartEngine.getPeriodicity().timeUnit
      )
      store.dispatch(
        chartUnsubscribe(
          this.props.contractNameToIdMap[
            this.getContractNameFromId(this.props.chart.contractId)
            ],
          periodSeconds,
          this.state.chartConfig.chartId
        )
      )

      this.state.chartEngine.container.remove()
      this.state.chartEngine.destroy()
    }
  }

  _getContractName = (props: ChartProps) => {
    let contractId = '-'
    if (props.chart.syntheticContractId) {
      const contract: Contract = convertSyntheticToConcrete(props.chart.syntheticContractId)
      contractId = contract?.idString
    } else {
      contractId = props.chart.contractId
    }

    if (props.contracts[contractId]) {
      return props.contracts[contractId].nameWithVenue
    }
    return '-'
  }

  render() {
    return (
      <div
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          overflow: 'hidden',
        }}
        ref={this.chartRef}
        id={this.props.chart.id}
      >
        <Chart
          config={this.state.chartConfig}
          resources={{ quoteFeed: this.state.quoteFeed }}
        />
      </div>
    )
  }
}
