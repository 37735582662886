import { State } from '../../main/reducers/rootReducer';
import { createSelector } from 'reselect';
import { DockType } from '../../shared/dock/models/dock';
import { argsSelectorCreator } from '../../shared/utils/selectors/agrSelector';
import { getTabNamesForDock, getChartsForDock, getMarketsForDock } from '../../shared/ui/selectors/ui';
import { IChart, Chart } from '../../shared/ui/models/chart';
import { I18n } from 'react-redux-i18n';

// Min. height of dock
const dockHeightLimit = 150;

export const getDashboardState = (state: State) => state.dashboard;

export const getEntities = createSelector(
  getDashboardState,
  s => s.entities
);
export const getIds = createSelector(
  getDashboardState,
  s => s.ids
);
export const getActiveId = createSelector(
  getDashboardState,
  s => s.activeDockId
);

export const getActive = createSelector(
  getEntities,
  getActiveId,
  (entities, activeId) => (activeId ? entities[activeId] : null)
);

export const getAll = argsSelectorCreator(
  getEntities,
  getIds,
  (entities, ids) => ids.map(id => entities[id])
);

export const getVisibleDocks = argsSelectorCreator(
  getEntities,
  getIds,
  (entities, ids) => ids.map(id => entities[id]).filter(dock => !dock.hidden)
);


export const isDockVisible = createSelector(
  [getEntities, (state: State, dockId: string) => dockId],
  (entities, id) => !entities[id]?.hidden
)


export const getDockById = createSelector(
  [getEntities, (state: State, dockId: string) => dockId],
  (entities, id) => entities[id]
);

export const getDockByElementId = createSelector(
  [getEntities, (state: State, elementId: string) => elementId],
  (entities, id) => {
    if (id.indexOf('dock-') === 0) {
      return entities[id.replace('dock-', '')];
    }
    return undefined;
  });

export const getAllInactive = createSelector(
  getAll,
  getActiveId,
  (docks, activeId) => docks.filter(entity => entity.id !== activeId)
);

export const getCollidingDocks = argsSelectorCreator(getAll, docks =>
  docks.filter(entity => entity.isColliding)
);

export const getCollidingDocksOnDockId = argsSelectorCreator(
  [getCollidingDocks, (state: State, dockId: string) => dockId],
  (docks, dockId) => docks.filter(entity => entity.id !== dockId)
);
const findContentNamesForDockId = createSelector(
  [(state: State, dockId: string, type: DockType) => ({ state, dockId, type })],
  (data) => {
    const { state, dockId, type } = data;
    switch (type) {
      case DockType.Tabs: {
        return getTabNamesForDock(state, dockId).map(name => I18n.t(name));
      }

      case DockType.Market:
      case DockType.ProductMarket:
      case DockType.MarketIntraday: {
        const markets = getMarketsForDock(state, dockId);
        return markets.map(c => c.title);
      }

      case DockType.ChartMarket: {
        const charts: (Chart | IChart)[] = getChartsForDock(state, dockId);
        return charts.map(c => c.title);
      }

      default:
        return [];
    }
  });

export const getContentNamesForDockId = argsSelectorCreator(
  [findContentNamesForDockId],
  (names) => names);

const findDockFavoriteName = argsSelectorCreator(
  [getAll, (state: State, dockId: string) => dockId],
  (docks, dockId) => {
    const dock = docks.find(entity => entity.id === dockId);
    return dock ? dock.favoriteName : undefined;
  }
);

export const getDockFavoriteName = argsSelectorCreator(
  [findDockFavoriteName],
  favoriteName => favoriteName
);

const findDocksBellowSizeLimit = argsSelectorCreator(
  [getAll],
  docks => docks.filter(dock => dock.size.height < dockHeightLimit)
);

export const checkDockBellowLimit = argsSelectorCreator(
  [findDocksBellowSizeLimit, (state: State, dockId: string) => dockId],
  (docks, dockId) => {
    if (dockId) {
      return docks.filter(dock => dock.id === dockId).length > 0;
    } else {
      return docks.length > 0;
    }
  }
);

export const getMarketsDocks = argsSelectorCreator(
  [getAll],
  docks =>
    docks.filter(
      dock =>
        dock.type === DockType.Market || dock.type === DockType.MarketIntraday || dock.type === DockType.ProductMarket
    )
);

export const getColumnWidth = argsSelectorCreator(
  [getEntities, (state: State, dockId: string) => dockId],
  (entities, id) =>  {
    return entities[id]?.blockWidth;
  }
);

export const getDockScrollState  = argsSelectorCreator(
  [getEntities, (state: State, dockId: string) => dockId],
  (entities, id) =>  {
    const scrollState = {
      top: entities[id]?.scrollState?.top === undefined ? 0 : entities[id]?.scrollState.top,
      left: entities[id]?.scrollState?.left === undefined ? 0 : entities[id]?.scrollState.left
    }
    
    return scrollState;
  }
);


export const getDockTitlesMap = argsSelectorCreator(
  [getEntities, (state: State) =>  state],
    (entities, state) => {
      return Object.keys(entities).map(k => entities[k]).reduce((acc, dock) => {

        const names = findContentNamesForDockId(state, dock.id, dock.type);
        let name: string = '' + dock.type;
        if (names.length > 0) {
          name = names[0];
        }
        if (names.length > 1) {
          name += ' + ' + (names.length - 1);
        }
        acc[dock.id] = name;
        return acc;
      }, {})
  }
);

export const getExpiryOrder = createSelector(
  [getDashboardState],
  (s) =>  {
    return s.dockExpiryOrder;
  }
);

export const getExpiryOrderForDock = createSelector(
  [getDashboardState, (state: State, dockId: string) => dockId],
  (state, id) => {
    return state.dockExpiryOrder[id];
  }
);

export const isDashboardLoading = createSelector(
  [getDashboardState],
  (state) => {
    return state.dashboadrLoading
  }
);

