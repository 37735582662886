import {
    ActionsObservable,
    combineEpics,
    StateObservable
} from 'redux-observable';
import * as Rx from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import ChartService from '../services/charting';
import { SubscribeChartDataRequest, UnsubscribeChartDataRequest } from '../models/charts';
import { ActionTypes as Charts } from '../../shared/ui/actions/chart';
import { State } from '../../main/reducers/rootReducer';

const chartService = new ChartService();





export const subscribeChartToContract: any = (
    actions$: ActionsObservable<any>,
    state: StateObservable<State>
) => {
    return actions$.pipe(
        filter(action => action.type === Charts.SUBSCRIBE_CHART),
        map(actions => actions.payload),
        mergeMap((data) => {
            if (data.contractId) {
                let request: SubscribeChartDataRequest = {
                    dockId: data.dockId,
                    contractId: data.contractId,
                    periodSeconds: data.periodSeconds

                };
                chartService.subscribeChartData(request);
            }
            return Rx.empty();
        })
    );
};

export const unsubscribeChartContract: any = (
    actions$: ActionsObservable<any>,
    state: StateObservable<State>
) => {
    return actions$.pipe(
        filter(action => action.type === Charts.UNSUBSCRIBE_CHART),
        map(action => action.payload),
        mergeMap(action => { 
            let request: UnsubscribeChartDataRequest = {
                contractId: action.contractId,
                periodSeconds: action.periodSeconds,
                chartId: action.chartId
            };
            chartService.unsubscribeChartData(request);
            return Rx.empty();
        })
    );
};

export const chartEpics = combineEpics(
    subscribeChartToContract,
    unsubscribeChartContract
);
