import { IDock, DockType } from '../models/dock'
import * as Dashboard from '../../../dashboard/actions/dashboard'
import * as Profile from '../../../dashboard/actions/profile'
import * as Dock from '../actions/dock'
import * as Market from '../../ui/actions/market'
import * as Tab from '../../ui/actions/tab'
import * as Chart from '../../ui/actions/chart'
import { Contract } from 'js/orderbook/models/contracts'
import {Grid} from "../../utils/components/grid";

export interface State {
  [id: string]: IDock
}

export const initialState: State = {}

const setIsBeingDragged: any = (
  isBeingDragged: boolean,
  id: string,
  oldState: State
) => {
  return Object.keys(oldState).reduce((acc: any, key: string) => {
    acc[key] = Object.assign({}, oldState[key], {
      isBeingDragged: id === key && isBeingDragged,
      active: true,
    })
    return acc
  }, {})
}

export function reducer(
  state: State = initialState,
  action:
    | Dashboard.Action
    | Dock.Action
    | Profile.Action
    | Market.Action
    | Chart.Action
    | Tab.Action
) {
  switch (action.type) {
    case Dock.ActionTypes.LOAD: {
      const { docks } = action.payload
      const newState: { [id: string]: IDock } = docks.reduce(
        (entities: { [id: string]: IDock }, dock: IDock) => {
          const grid = new Grid()
          let sizePx = dock.size
          if (dock.size.relative) {
            sizePx = grid.convertDockSizeToPx(dock.size)
          }
          return {
            ...entities,
            [dock.id]: {
              ...dock,
              position: grid.convertDockPositionToPx(dock.position),
              size: sizePx,
              type: dock.type.startsWith('Chart')
                ? DockType.ChartMarket
                : dock.type,
            },
          }
        },
        {}
      )
      return newState
    }

    case Dock.ActionTypes.CREATE: {
      const newDock = action.payload
      const newState = Object.assign({}, state, {
        [newDock.id]: newDock,
      })

      return { ...state, ...newState }
    }

    case Dock.ActionTypes.REMOVE: {
      const dockId = action.payload
      const copy = Object.assign({}, state)
      delete copy[dockId]
      return copy
    }

    case Dock.ActionTypes.SET_VISIBILITY: {
      const {dockId, visible} = action.payload
      const newState = Object.assign({}, state, {
        [dockId]: Object.assign({}, state[dockId], {
          hidden: !visible
        }),
      })
      return newState
    }

    case Dock.ActionTypes.PIN_START: {
      const { id, pinning } = action.payload
      const newState = Object.assign({}, state, {
        [id]: Object.assign({}, state[id], {
          pinning: pinning,
        }),
      })
      return { ...state, ...newState }
    }

    case Dock.ActionTypes.PIN_END: {
      const id = action.payload
      const newState = Object.assign({}, state, {
        [id]: Object.assign({}, state[id], {
          pinning: null,
        }),
      })
      return { ...state, ...newState }
    }

    case Dock.ActionTypes.INACTIVE: {
      const { id, position, size } = action.payload
      const newState = setIsBeingDragged(false, id, state)
      newState[id] = Object.assign({}, newState[id], {
        position: position,
        size: size,
        pinning: null,
        autoDragging: false,
        ableToDelete: false,
        disableRendering: false,
        active: false,
      })
      return { ...state, ...newState }
    }

    case Dock.ActionTypes.ACTIVE: {
      const { id, isBeingDragged } = action.payload
      const newState = setIsBeingDragged(isBeingDragged, id, state)
      return { ...state, ...newState }
    }

    case Dock.ActionTypes.COLLIDE_START: {
      const { id, isRemoving } = action.payload
      const newState = Object.assign({}, state, {
        [id]: Object.assign({}, state[id], {
          isColliding: true,
          isRemoving: isRemoving,
        }),
      })
      return { ...state, ...newState }
    }

    case Dock.ActionTypes.COLLIDE_END: {
      const id = action.payload
      const newState = setIsBeingDragged(false, id, state)
      Object.assign(newState, {
        [id]: Object.assign({}, state[id], {
          isColliding: false,
          isRemoving: false,
        }),
      })
      return { ...state, ...newState }
    }

    case Dashboard.ActionTypes.RESIZE: {
      const { meta, prevGrid } = action.payload
      if (!prevGrid) {
        return state
      }
      const grid = new Grid(meta.size.width, meta.size.height)
      const updatedEntities = Object.keys(state).reduce(
        (entities: { [id: string]: IDock }, key: string) => {
          if (!state[key] || !state[key].position) {
            // no position available
            return {
              ...entities,
            }
          }
          const position = prevGrid.convertDockPositionToPercentage(
            state[key].position
          )
          const size = prevGrid.convertDockSizeToPercentage(state[key].size)
          const sizePx = grid.convertDockSizeToPx(size)
          const positionPx = grid.convertDockPositionToPx(position)

          return {
            ...entities,
            [key]: {
              ...state[key],
              position: positionPx,
              size: {
                ...state[key].size,
                width: sizePx.width,
                height: sizePx.height,
              },
            },
          }
        },
        {}
      )

      return {
        ...state,
        ...updatedEntities,
      }
    }

    case Dock.ActionTypes.SCROLL_CONTENT: {
      const id = action.dockId
      const newState = Object.assign({}, state, {
        [id]: Object.assign({}, state[id], {
          scrollState: { top: action.top, left: action.left },
        }),
      });
      return { ...state, ...newState }
    }

    case Dashboard.ActionTypes.CONNECT_DOCKS: {
      const { fromId, toId } = action.payload
      const newState = setIsBeingDragged(false, fromId, state)
      Object.assign(newState, {
        [fromId]: Object.assign({}, state[fromId], {
          favoriteName: '',
        }),
        [toId]: Object.assign({}, state[toId], {
          favoriteName: '',
        }),
      })
      return { ...state, ...newState }
    }

    case Dock.ActionTypes.TOGGLE_FAVORITE: {
      const { id, favoriteName } = action.payload
      // remove favorite name from another dock if it was the same and assign it to the new one
      const newState = Object.keys(state).reduce((acc: any, dockId: string) => {
        if (dockId !== id && state[dockId].favoriteName === favoriteName) {
          acc[dockId] = { ...state[dockId], favoriteName: '' }
        } else if (dockId !== id) {
          acc[dockId] = { ...state[dockId] }
        } else if (dockId === id) {
          acc[dockId] = { ...state[dockId], favoriteName: favoriteName }
        }
        return acc
      }, {})

      return { ...state, ...newState }
    }

    case Dock.ActionTypes.SET_BLOCK_WIDTH: {
      const { dockId, blockWidth } = action
      const newState = Object.assign({}, state, {
        [dockId]: Object.assign({}, state[dockId], {
          blockWidth: blockWidth,
        }),
      })

      return { ...state, ...newState }
    }

    case Market.ActionTypes.MOVE:
    case Chart.ActionTypes.MOVE: {
      const { toDockId, dockId } = action.payload
      const newState = Object.assign({}, state, {
        [toDockId]: Object.assign({}, state[toDockId], {
          parts: state[toDockId].parts + state[dockId].parts,
        }),
      })

      return { ...state, ...newState }
    }

    case Market.ActionTypes.REMOVE: {
      const { dockId } = action.payload
      if (state[dockId]) {
        const newState = Object.assign({}, state, {
          [dockId]: Object.assign({}, state[dockId], {
            favoriteName: '',
            parts: state[dockId].parts - 1,
          }),
        })

        return { ...state, ...newState }
      }
      return state
    }
    case Chart.ActionTypes.REMOVE: {
      const { dockId } = action.payload
      if (state[dockId]) {
        const newState = Object.assign({}, state, {
          [dockId]: Object.assign({}, state[dockId], {
            parts: state[dockId].parts - 1,
          }),
        })

        return { ...state, ...newState }
      }

      return state
    }
    case Market.ActionTypes.TRIGGER_COLUMN_NAMES:
    case Market.ActionTypes.TRIGGER_EXPIRIES:
    case Market.ActionTypes.TRIGGER_EXPIRY_ROWS:
    case Chart.ActionTypes.SET_PERIOD_TYPE:
    case Chart.ActionTypes.SET_CONTRACT:
      {
        const setContractPayload = action.payload as { id: string; contract: Contract; dockId: string; }
        const { id, contract, dockId } = setContractPayload
        if (contract) {
          const newState = Object.assign({}, state, {
            [dockId]: Object.assign({}, state[dockId], {
              title: contract.nameWithVenue,
            }),
          })
          return { ...state, ...newState }
        }
        
        return state
      }
    case Chart.ActionTypes.SET_GROUP_TYPES: {
      const { dockId } = action.payload
      const newState = Object.assign({}, state, {
        [dockId]: Object.assign({}, state[dockId], {
          favoriteName: '',
        }),
      })

      return { ...state, ...newState }
    }
    case Market.ActionTypes.CREATE: {
      const { dockId } = action.payload
      const newState = Object.assign({}, state, {
        [dockId]: Object.assign({}, state[dockId], {
          parts: state[dockId].parts + 1,
        }),
      })

      return { ...state, ...newState }
    }
    case Chart.ActionTypes.CREATE: {
      const { dockId } = action.payload
      const newState = Object.assign({}, state, {
        [dockId]: Object.assign({}, state[dockId], {
          parts: state[dockId].parts + 1,
        }),
      })

      return { ...state, ...newState }
    }

    case Tab.ActionTypes.CREATE: {
      const { dockId } = action.payload
      if (state[dockId]) {
        const newState = Object.assign({}, state, {
          [dockId]: Object.assign({}, state[dockId], {
            parts: state[dockId].parts + 1,
          }),
        })

        return { ...state, ...newState }
      }

      return { ...state }
    }
    case Tab.ActionTypes.REMOVE: {
      const { dockId } = action.payload
      if (state[dockId]) {
        const newState = Object.assign({}, state, {
          [dockId]: Object.assign({}, state[dockId], {
            parts: state[dockId].parts - 1,
          }),
        })
        return { ...state, ...newState }
      }

      return state
    }

    default:
      return state
  }
}
