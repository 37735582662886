import * as React from 'react';

import { connect } from 'react-redux';
import { State } from '../../../main/reducers/rootReducer';
import {
  INotification,
  Notification as NotificationModel
} from '../models/notification';
import { getNotificationsForClientLevel } from '../selectors/notifications';
import { removeNotification, createNotification } from '../actions/notifications';
import { isPushNotificationsEnabled } from '../../../requests/selectors/requests';
import { Translate } from 'react-redux-i18n';

interface NotificationProps {
  notification: INotification;
  onRemove: (id: string) => void;
}

interface NotificationState { }

class NotificationComponent extends React.Component<
  NotificationProps,
  NotificationState
  > {
  constructor(props: NotificationProps) {
    super(props);
    this.startRemove = this.startRemove.bind(this);
  }

  startRemove(e: any) {
    this.props.onRemove(this.props.notification.id);
  }

  render() {
    const { notification } = this.props;
    return (
      <div
        className={`meet-notification alert alert-${notification.type.toLowerCase()}${notification.confirmationRequired ? ' confirmed' : ''}`}
        role="alert"
        onClick={notification.confirmationRequired ? (e) => undefined : this.startRemove}
      >
        {notification.message}
        {notification.confirmationRequired ? (<div className="notification-confirmation" onClick={this.startRemove}><Translate value="notifications.ok" /></div>) : ''}
      </div>
    );
  }
}

const NotificationComponentMemoized = React.memo(NotificationComponent);

interface NotificationsProps {
  notifications: INotification[];
  create: (notification: INotification) => void;
  remove: (id: string) => void;
  pushNotificationsEnabled: boolean;
}

interface NotificationsState {
  pushNotificationInitialized: boolean;
}

export class NotificationsComponent extends React.Component<
  NotificationsProps,
  NotificationsState
  > {
  constructor(props: NotificationsProps) {
    super(props);
    this.onCreate = this.onCreate.bind(this);
    this.onNotificatioRemove = this.onNotificatioRemove.bind(this);

    this.state = {
      pushNotificationInitialized: false
    };
  }

  onCreate(notification: INotification) {
    this.props.create(
      new NotificationModel(notification.type, notification.message)
    );
  }

  onNotificatioRemove(id: string) {
    this.props.remove(id);
  }

  render() {
    const { notifications } = this.props;
    const notificationList = notifications.map(
      (n: INotification, i: number) => (
        <NotificationComponentMemoized
          key={n.id}
          notification={n}
          onRemove={this.onNotificatioRemove}
        />
      )
    );
    return (
      <React.Fragment>
        {<div className="meet-notifications">{notificationList}</div>}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: State) => ({
  notifications: getNotificationsForClientLevel(state),
  pushNotificationsEnabled: isPushNotificationsEnabled(state)
});

const mapDispatchToProps = {
  create: createNotification,
  remove: removeNotification,
};

export default connect<any, any, any>(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsComponent);