import { combineReducers } from 'redux';

import * as fromAuth from '../../authentication/reducers/authentication';
import * as fromConnection from '../../authentication/reducers/connection';
import * as fromDashboard from '../../dashboard/reducers/dashboard';
import * as fromProfile from '../../dashboard/reducers/profile';
import * as fromInstruments from '../../instruments/reducers/instruments';
import * as fromOrders from '../../orders/reducers/orders';
import * as fromTrade from '../../trade/reducers/trade';
import * as fromOwnTrade from '../../trade/reducers/owntrade';
import * as fromRequests from '../../requests/reducers/requests';
import * as fromUI from '../../shared/ui/reducers/ui';
import * as fromLogger from '../../shared/logger/reducers/reducer';
import * as fromMessenger from '../../shared/messenger/reducers/messenger';
import * as fromSettings from '../../shared/settings/reducers/settings';
import * as fromNotifications from '../../shared/notifications/reducers/notifications';
import * as fromI18n from '../../shared/i18n/reducers/translations';
import * as fromFavorite from '../../shared/favorite/reducers/favorite';
import * as fromConfiguration from '../../authentication/reducers/configuration';
import * as fromBulkOrders from '../../bulkOrders/reducers/bulkOrders';
import * as fromPassword from '../../shared/passwordChange/reducers/passwordChange';
import * as fromBusinessUnits from '../../businessUnitPortfolios/reducers/businessUnits';
import * as fromPriceAlarms from '../../priceAlarm/reducers/priceAlarms';
import * as fromVenues from '../../shared/venues/reducers/venue';
import * as location from '../../authentication/reducers/location';
import { ActionTypes, cleaned } from '../../authentication/actions/connection';

export interface State {
  connection: fromConnection.State;
  auth: fromAuth.State;
  dashboard: fromDashboard.State;
  profile: fromProfile.State;
  instruments: fromInstruments.State;
  trades: fromTrade.State;
  ownTrades: fromOwnTrade.State;
  orders: fromOrders.State;
  requests: fromRequests.State;
  ui: fromUI.State;
  logs: fromLogger.State;
  messager: fromMessenger.State;
  notifications: fromNotifications.State;
  i18n: fromI18n.State;
  favorites: fromFavorite.State;
  clientConfiguration: fromConfiguration.State;
  settings: fromSettings.State;
  bulkOrders: fromBulkOrders.State;
  password: fromPassword.State;
  businessUnits: fromBusinessUnits.State;
  priceAlarms: fromPriceAlarms.State;
  venues: fromVenues.State,
  location: location.State,
}

export const initialState: State = {
  connection: fromConnection.initialState,
  auth: fromAuth.initialState,
  dashboard: fromDashboard.initialState,
  profile: fromProfile.initialState,
  instruments: fromInstruments.initialState,
  trades: fromTrade.initialState,
  ownTrades: fromOwnTrade.initialState,
  orders: fromOrders.initialState,
  requests: fromRequests.initialState,
  ui: fromUI.initialState,
  logs: fromLogger.initialState,
  messager: fromMessenger.initialState,
  i18n: fromI18n.initialState,
  favorites: fromFavorite.initialState,
  notifications: fromNotifications.initialState,
  clientConfiguration: fromConfiguration.initialState,
  settings: fromSettings.initialState,
  bulkOrders: fromBulkOrders.initialState,
  password: fromPassword.initialState,
  businessUnits: fromBusinessUnits.initialState,
  priceAlarms: fromPriceAlarms.initialState,
  venues: fromVenues.initialState,
  location: location.initialState
};

export const rootReducer = combineReducers<State>({
  connection: fromConnection.reducer,
  auth: fromAuth.reducer,
  dashboard: fromDashboard.reducer,
  profile: fromProfile.reducer,
  instruments: fromInstruments.reducer,
  trades: fromTrade.reducer,
  ownTrades: fromOwnTrade.reducer,
  orders: fromOrders.reducer,
  requests: fromRequests.reducer,
  ui: fromUI.reducer,
  logs: fromLogger.reducer,
  messager: fromMessenger.reducer,
  i18n: fromI18n.reducer,
  favorites: fromFavorite.reducer,
  notifications: fromNotifications.reducer,
  clientConfiguration: fromConfiguration.reducer,
  settings: fromSettings.reducer,
  bulkOrders: fromBulkOrders.reducer,
  password: fromPassword.reducer,
  businessUnits: fromBusinessUnits.reducer,
  priceAlarms: fromPriceAlarms.reducer,
  venues: fromVenues.reducer,
  location: location.reducer,
});

export const state = (rootState: State, action: any) => {
  if (action.type === ActionTypes.CLOSE_CLEAN) {
    // errors to be displayed on login page should be left
    rootState = {
      ...initialState,
      connection: {
        ...initialState.connection,
        error: rootState.connection.error
      },
      auth: {
        ...initialState.auth,
        error: rootState.auth.error
      },
      i18n: rootState.i18n
    };

    return rootReducer(rootState, cleaned());
  }

  return rootReducer(rootState, action);
};